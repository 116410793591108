import { constants, Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  return Yup.object({
    inputPartyID: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1.')
      .max(35, 'Max length allowed is 35.')
      .required(requiredMessage)
      .matches(
        '[a-zA-Z0-9\\-\\_]$',
        'Only alphanumeric values along with - and _ is allowed.'
      )
      .nullable(),
    partyName: Yup.string()
      .min(1, 'Min length needed is 1.')
      .max(60, 'Max length allowed is 60.')
      .required(requiredMessage)
      .matches(
        "[a-zA-Z0-9\\-\\_\\+\\ \\$\\#\\@\\%\\&\\(\\)\\!\\'\\`\\^\\:\\;\\.\\?\\[\\]\\,\\{\\}]$",
        'Only alphanumeric values are with some special character is allowed'
      )
      .nullable(),
    nameDBA: Yup.string()
      .max(50, 'Max length allowed is 50.')
      .matches(
        "[a-zA-Z0-9\\-\\_\\+\\ \\$\\#\\@\\%\\&\\(\\)\\!\\'\\`\\^\\:\\;\\.\\?\\[\\]\\,\\{\\}]$",
        'Only alphanumeric values are with some special character is allowed'
      )
      .nullable(),
    partyType: Yup.string().nullable().required(requiredMessage),
    address1: Yup.string()
      .max(35, 'Max length allowed is 35.')
      .matches(
        "[a-zA-Z0-9\\-\\_\\+\\ \\$\\#\\@\\%\\&\\(\\)\\!\\'\\`\\^\\:\\;\\.\\?\\[\\]\\,\\{\\}]$",
        'Only alphanumeric values are with some special character is allowed'
      )
      .nullable()
      .required(requiredMessage),
    address2: Yup.string()
      .max(35, 'Max length allowed is 35.')
      .matches(
        "[a-zA-Z0-9\\-\\_\\+\\ \\$\\#\\@\\%\\&\\(\\)\\!\\'\\`\\^\\:\\;\\.\\?\\[\\]\\,\\{\\}]$",
        'Only alphanumeric values are with some special character is allowed'
      )
      .nullable(),
    address3: Yup.string()
      .max(35, 'Max length allowed is 35.')
      .matches(
        "[a-zA-Z0-9\\-\\_\\+\\ \\$\\#\\@\\%\\&\\(\\)\\!\\'\\`\\^\\:\\;\\.\\?\\[\\]\\,\\{\\}]$",
        'Only alphanumeric values are with some special character is allowed'
      )
      .nullable(),
    cityName: Yup.string()
      .max(30, 'Max length allowed is 30.')
      .matches(
        "[a-zA-Z0-9\\-\\_\\+\\ \\$\\#\\@\\%\\&\\(\\)\\!\\'\\`\\^\\:\\;\\.\\?\\[\\]\\,\\{\\}]$",
        'Only alphanumeric values are with some special character is allowed'
      )
      .nullable(),
    postalCode: Yup.string().max(15, 'Max length allowed is 15.').nullable(),
    contactPerson: Yup.string()
      .max(60, 'Max length allowed is 60.')
      .matches(
        "[a-zA-Z0-9\\-\\_\\+\\ \\$\\#\\@\\%\\&\\(\\)\\!\\'\\`\\^\\:\\;\\.\\?\\[\\]\\,\\{\\}]$",
        'Only alphanumeric values are with some special character is allowed'
      )
      .nullable(),
    telNo: Yup.string()
      .max(35, 'Max length allowed is 35.')
      .matches(
        "[a-zA-Z0-9\\-\\_\\+\\ \\$\\#\\@\\%\\&\\(\\)\\!\\'\\`\\^\\:\\;\\.\\?\\[\\]\\,\\{\\}]$",
        'Only alphanumeric values are with some special character is allowed'
      )
      .nullable(),
    faxNo: Yup.string()
      .matches(
        '[0-9\\-\\+\\(\\)\\ ]$',
        'Only alphabets some special character is allowed'
      )
      .max(35, 'Max length allowed is 35.')
      .nullable(),
    email: Yup.string()
      .matches('[A-Za-z0-9+_.-]+@(.+)$', 'Enter Email in valid pattern')
      .max(50, 'Max length allowed is 50.')
      .nullable(),
    partyIdentifierSection: Yup.array().nullable(),
    scac: Yup.string().max(4, 'Max length allowed is 04.')
  })
}

export default makeValidationSchema
